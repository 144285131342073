<script>
import {list, deleteServidor} from '@/domain/gerencia/servidor/services'
import {date} from 'uloc-vue'
import {datePtToEn} from '@/utils/date'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import {listName, listStorage} from "../config/list"
import ListMixin from "@/reuse/list/ListMixin"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import windowServidor from '../windows/servidor'

const filters = {
  id: null,
  active: true,
  deleted: false,
  search: null,
  status: null
}

export default {
  name: 'ListaServidores',
  mixins: [DefaultMixin, ListMixin, GlobalPersonMixin],
  components: {
    // DateInput,
    ErpInput,
    ErpSField,
    // ErpSelect
  },
  data() {
    const _filters = JSON.parse(JSON.stringify(filters))
    return {
      listName: listName,
      listType: 'a',
      filters: _filters
    }
  },
  watch: {
    'filters.status'(v) {
      this.$nextTick(() => {
        this.pesquisar()
      })
    }
  },
  computed: {},
  beforeCreate() {
    this.listStorage = listStorage
  },
  mounted() {
    this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    windowServidor,
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.filters.data1) {
        if (this.filters.data1.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.filters.data1)
        data2 = this.filters.data2 ? datePtToEn(this.filters.data2) : data1
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.filters.tipoData && extraFilters.push(`&typeDate=${this.filters.tipoData}`)

      this.filters.id && extraFilters.push(`&id=${this.filters.id}`)
      this.filters.search && extraFilters.push(`&search=${this.filters.search}`)

      if (Array.isArray(this.filters.status) && this.filters.status.length > 0) {
        extraFilters.push(`&status=${this.filters.status.join(',')}`)
      }

      this.filters.bem && extraFilters.push(`&bem=${this.filters.bem.id || this.filters.bem}`)
      this.filters.pessoa && extraFilters.push(`&pessoa=${this.filters.pessoa.id || this.filters.pessoa}`)
      this.filters.status !== null && extraFilters.push(`&status=${this.filters.status}`)

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    novo(t = null) {
      console.log('New...')
      this.windowServidor(null)
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.filters.descricao || this.table.busca
      })
    },
    abrir(id) {
      this.$router.push({name: 'gerencia.servidor.show', params: {id: id}})
    },
    edit(id) {
      this.$router.push({name: 'gerencia.servidor.show', params: {id: id}})
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este registro? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir ' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteServidor(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Servidor excluído com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    label(a, b) {
      return a
    }
  },
  meta: {
    title: 'Servidores',
    name: 'Servidores'
  }
}
</script>

<template>
  <div class="vistoria-container mw-1200 wrapper-md">
    <e-row class="m-b-lg">
      <e-col style="max-width: 350px">
      </e-col>
      <e-col class="justify-end flex" style="max-width: 200px">
      </e-col>
    </e-row>
    <div class="erp-list list-tasks no-pad">
      <div class="erp-list-top-btns items-end">
        <div class="box-default__ end font-14 flex-zero flex no-wrap m-b wrapper-sm__">
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="ll"
                label="Buscar"
            >
              <erp-input shortkey="F3" v-model="filters.search" @keydown.enter="pesquisar"/>
            </erp-s-field>
          </e-col>
        </div>
        <div class="row erp-row">
          <div class="col flex justify-end m-t-sm def-list-buttons" style="min-width: 300px">
            <div class="flex flex-center column">
              <u-btn @click="novo" class="cadastrar bg-positive" label="Novo Servidor" v-shortkey="['shift', 'n']"
                     @shortkey.native="novo"/>
              <span class="shortcut">Shift + N</span>
            </div>
          </div>
        </div>
      </div>
      <u-table
          dark
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table erp-table-options table-default"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
              <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat
                     no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
            </div>
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="abrir(props.row.id)" slot="body" slot-scope="props"
              :props="props">
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>
          <u-td key="id" :props="props">
            {{ props.row.id }}
          </u-td>
          <u-td key="nome" :props="props">
            {{ props.row.nome }}
          </u-td>
          <u-td key="url" :props="props">
            {{ props.row.url }}
          </u-td>
          <u-td key="status" :props="props">

          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                           @excluir="excluir(props.row.id)" :leilao="props.row"/>
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>

      <!--<div class="flex justify-between">
        <div class="m-t">
          <u-btn icon="print" color="blue-grey-6" flat round no-caps>
            <u-tooltip :offset="[5,5]">Imprimir</u-tooltip>
          </u-btn>
          <u-btn icon="file_upload" color="blue-grey-6" flat round no-caps>
            <u-tooltip :offset="[5,5]">Exportar</u-tooltip>
          </u-btn>
        </div>
        <div>

        </div>
      </div>-->
    </div>
  </div>
</template>
