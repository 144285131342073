import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 0
export const listName = 'gerencia.servidor.lista'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
    {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 2},
    {label: 'Url', name: 'url', active: true, sortable: true, ordering: 3},
    {label: 'Status', name: 'status', active: true, sortable: true, ordering: 6},
], listVersion)
